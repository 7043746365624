<template>
  <a-modal :title="gCourse?.title" :visible="visible" :destroyOnClose="true" @ok="handleOk" @cancel="handleCancel" width="1000px">
    <div v-if="gCourse" class="main-content">
      <table class="table trainelists table-bordered ">
        <tr>
          <td class="course_title header" v-for="data in gCourse.groupCourses" :key="data._id">{{data.title}}</td>
        </tr>
        <tr>
          <td class="date-column" v-for="data in gCourse.groupCourses" :key="data._id">
            <div v-if="!data.certificate && data.total_chapter != data.total_chapter_read" class="not-started"></div>
            <div v-if="data.total_chapter === data.total_chapter_read && !data.certificate" class="failed"></div>
            <div v-if="data.certificate && data.certificate.remaining_days > 30" class="in-date">{{formatDate(data.certificate.exp_date)}}</div>
            <div v-if="data.certificate && data.certificate.remaining_days <= 30 && data.certificate.remaining_days > 0" class="in-30-date">{{formatDate(data.certificate.exp_date)}}</div>
            <div v-if="data.certificate && data.certificate.remaining_days <= 0" class="expired">{{formatDate(data.certificate.exp_date)}}</div>
          </td>
        </tr>
      </table>
    </div>
  </a-modal>
</template>

<script lang="ts">
import { defineComponent, ref, watch, onMounted } from 'vue'
import { notification } from 'ant-design-vue'

export default defineComponent({
  props: ['course', 'showGroupCourseModel'],
  setup (props) {
    const visible = ref<boolean>(false)
    const gCourse = ref<any>()
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({
        message,
        description
      })
    }
    const handleOk = () => {
      console.log('ok')
      visible.value = false
    }
    const handleCancel = () => {
      console.log('Clicked cancel button')
      visible.value = false
    }
    watch(() => props.showGroupCourseModel, () => {
      visible.value = true
      console.log('props.course', props.course)
      gCourse.value = props.course
    })
    onMounted(() => {
      console.log('Mounted')
    })
    return {
      visible,
      handleCancel,
      handleOk,
      notify,
      gCourse
    }
  }
})
</script>

<style lang="scss" scoped>
.main-content {
  overflow-x: scroll;
}
.main-content .table-bordered th,
.main-content .table-bordered td {
  border: 3px solid #ffffff !important;
  border-collapse: collapse;
  text-align: center;
  height: 70px;
}
table {
  background: #efefef;
  margin-bottom: 0rem;
  font-size: 14px;
  .header {
    background: #11CDEF;
    color: #ffffff !important;
    width: 130px;
    padding: 8px;
  }
  .body {
    border: 1px solid #A4A4A4 !important;
  }
  .date-column {
    vertical-align: middle;
    color: #ffffff;
    min-width: 150px;
  }
}
th {
  border-radius: 0px !important;
  vertical-align: bottom;
  border: none !important;
  background-color: #ffffff;
  color: #A4A4A4;
  background: #efefef;
}
.in-date {
  background: #2ac136;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.expired {
  background: #F6596F;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.in-30-date {
  background: #FFC788;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.failed {
  background: #11CDEF;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.not-started {
  background: #ffffff;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #A4A4A4;
  width: 100%;
}
</style>
