
import { defineComponent, ref, onMounted } from 'vue'
import { HomeOutlined } from '@ant-design/icons-vue'
import { notification } from 'ant-design-vue'

import moment from 'moment'

import profileService from '../../services/profile'
import searchService from '../../services/filter'
import groupService from '../../services/candidateGroup'
import commonServices from '../../services/common'

import GroupCourse from '@/components/client/matrix/groupCourse.vue'

interface Group {
  _id?: string;
  name: string;
  description: string;
  client?: string;
}

export default defineComponent({
  components: {
    HomeOutlined,
    GroupCourse
  },
  setup () {
    const candidateName = ref<Array<any>>([])
    const columns = ref<Array<any>>([])
    const datas = ref<Array<any>>([])
    const matrixDatas = ref<Array<any>>([])
    const selectedStatus = ref<string>('')
    const selectedCandidate = ref<string>('')
    const selectedGroup = ref<string>('')
    const selectedCourse = ref<string>('')
    const groups = ref<Array<Group>>()
    const gCourse = ref<Array<any>>([])
    const showGroupCourseModel = ref<number>(0)
    const loader = ref<boolean>(false)
    const openGroupCourseModal = (course) => {
      gCourse.value = course
      showGroupCourseModel.value++
    }
    const filterData = async () => {
      const profile = commonServices.getCurrentProfile()
      const responce = await profileService.getClientMatrix(profile._id, selectedCourse.value.toString())
      matrixDatas.value = responce.data
      datas.value = searchService.searchMatrix(matrixDatas.value, selectedCandidate.value, selectedGroup.value, selectedCourse.value, selectedStatus.value)
    }
    const formatDate = (value) => {
      return moment(value).format('DD/MM/YYYY')
    }
    const loadCss = async () => {
      setTimeout(() => {
        const getheight = window.getComputedStyle(
          document.querySelector('.trainelists .course_title')
        ).height
        document.querySelector<HTMLElement>(
          '.trainelists .candidate'
        ).style.height = getheight
        document.querySelector<HTMLElement>(
          '.trainelists .candidate'
        ).style.paddingLeft = '15px'
        document.querySelector<HTMLElement>(
          '.trainelists .candidate'
        ).style.paddingTop = '0px'

        const mult = (datas.value[0].courses.length + 1) * 130
        document.querySelector<HTMLElement>(
          'table.trainelists'
        ).style.width = `${mult}px`
      }, 100)
    }
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({ message, description })
    }
    const getGroups = async () => {
      try {
        const clientId = commonServices.getCurrentProfile()._id
        const responce = await groupService.getGroup(clientId)
        groups.value = responce.data
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const getMatrix = async () => {
      try {
        loader.value = true
        const clientId = commonServices.getClientId()
        const responce = await profileService.getClientMatrix(clientId, '')
        datas.value = responce.data
        console.log('datas.value', datas.value)
        matrixDatas.value = responce.data
        for (const resData of datas.value) {
          for (const course of resData.courses) {
            if (course.enroll) {
              if (course.enroll.expiry_date) {
                const currentDate = moment().format()
                const expDate = moment(course.enroll.expiry_date)
                const diff = expDate.diff(currentDate, 'days')
                course.enroll.available_days = diff
              } else {
                course.enroll.available_days = 'unlimited'
              }
            }
          }
        }
        loader.value = false
        loadCss()
      } catch (error) {
        loader.value = false
        await notify('Error', 'Something went wrong, please try again later', 'error')
      }
    }

    onMounted(() => {
      getMatrix()
      getGroups()
    })
    return {
      candidateName,
      columns,
      datas,
      filterData,
      moment,
      formatDate,
      matrixDatas,
      selectedStatus,
      selectedCandidate,
      selectedGroup,
      selectedCourse,
      groups,
      gCourse,
      openGroupCourseModal,
      showGroupCourseModel,
      loader
    }
  }
})
